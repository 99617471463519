
import React, { Component } from 'react'
import { QrCode as QrCodeProcessor } from 'javascript-qrcode';
import './QrCode.css'

export default class QrCode extends Component {
  constructor() {
    super();

    this.state = {
      matrix: null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      matrix: new QrCodeProcessor(nextProps.data).getData()
    }
  }

  render() {
    if (this.state.matrix == null) {
      return <div />
    }
    else {
      const size = this.state.matrix.length;

      return <div className='qr-code'>
        <svg viewBox={`0 0 ${size} ${size}`}>
          {
            this.state.matrix.map((line, i) =>
              <g ref={i}>
                {
                  line.map((pixel, j) =>
                    (pixel === 1 ?
                      <rect ref={j} x={j} y={i} width="1" height="1" />
                      :
                      [])
                  )
                }
              </g>
            )
          }
        </svg>
      </div>;
    }
  }
}
