import React, { Component } from 'react'
import './payment-components.css'
import { Card, Button } from './util-components' 
import QrCode from './QrCode'
import { STATUS_PROCESSING, STATUS_CONNECTING, STATUS_REGISTERED } from './util/error-messages';
import { MESSAGES } from './util/error-messages'

// regex matching QR codes, e.g. [alt text](QR code data)
const QR_CODE_REGEX = /!\[.*\]\((.*)\)/

/**
 * The log displaying during a payment operation.
 * @param {Object} props
 * @param {string[]} props.logEntries
 */
export class LogPanel extends Component {
  render() {
    let logEntries = this.props.logEntries || []

    return <div className='log-panel'>
      {
        logEntries.map((entry, i) => {
          let match = entry.match(QR_CODE_REGEX)

          return match
          ? <div className='entry' key={ i.toString() }>
              <a href={match[1]} target='qrlanding'>
                <QrCode data={ match[1] }/>
              </a>
            </div>
          : <div className='entry' key={ i.toString() }>{ entry }</div>
        })
      }
      <div style={{ float:"left", clear: "both" }}
            ref={(el) => { this.messagesEnd = el; }}>
      </div>
    </div>
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
}

/**
 * Dialog for showing payment progress.
 * @param {Object} props
 * @param {string[]} props.logEntries
 * @param {string[]} props.status
 * @param {string[]} props.userCopyOutput
 * @param { () => void } props.onClose
 * @param { () => void } props.onCancel
 */
export function StatusDialog(props) {
  let isStillWorking =
    props.status === STATUS_CONNECTING
    || props.status === STATUS_PROCESSING
    || props.status === STATUS_REGISTERED

  return <Card title={ props.title } className='status-dialog'>
    <Spinner status={ isStillWorking ? '' : MESSAGES.en[props.status] || props.status } />
    <LogPanel logEntries={ props.logEntries } />
    <div className='button-bar'>
      {
        isStillWorking ?
          <Button
              type='flat'
              onClick={ props.onCancel }>
            Cancel
          </Button>
        :
        <Button
            type='flat'
            onClick={ props.onClose }>
          Close
        </Button>
      }
    </div>
  </Card>
}

/**
 * Spinner showing an animated bar code unless the `status` property is set, in which case it shows as status.
 */
export class Spinner extends Component {
  // the sizes of the bars in the animated bar code, normalized to a scale of 0..1
  BARS = [
    { x: 0, w: 30 },
    { x: 55, w: 45 },
    { x: 123, w: 27 },
    { x: 191, w: 30 },
    { x: 258, w: 18 },
    { x: 310, w: 30 },
    { x: 382, w: 27 },
    { x: 434, w: 45 },
    { x: 502, w: 30 }
  ]
  .map(rect => ({
    x: (rect.x / 532), w: (rect.w / 532)
  }))

  PAUSE_BETWEEN_BARS = 300
  PAUSE_BETWEEN_ITERATIONS = 500
  ANIMATION_DURATION = 500
  BARCODE_WIDTH = 200
  CANVAS_WIDTH = 1000
  CANVAS_HEIGHT = this.CANVAS_WIDTH/10

  constructor() {
    super()

    this.state = {
      isFallingFromRight: false,
      currentlyFalling: 0,
      iteration: 1
    }
  }

  startFalling() {
    if (this.props.status) {
      return
    }

    for (let idx = 0; idx < this.BARS.length; idx++) {
      let i = idx

      setTimeout(() => {
        this.setState({
          currentlyFalling: i+1
        })
      }, i * this.PAUSE_BETWEEN_BARS)
    }
  }

  fallFromOtherSide() {
    this.setState({
      isFallingFromRight: !this.state.isFallingFromRight,
      currentlyFalling: 0,
      iteration: this.state.iteration+1,
      className: null
    })

    setTimeout(
      () => this.startFalling(),
      this.PAUSE_BETWEEN_ITERATIONS)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  componentDidMount() {
    this.startFalling()

    this.timer = setInterval(
      () => this.fallFromOtherSide(),
      this.PAUSE_BETWEEN_ITERATIONS
      + this.BARS.length * this.PAUSE_BETWEEN_BARS
      + this.ANIMATION_DURATION)
  }

  render() {
    if (this.props.status) {
      return <div className='spinner status'>
        <div>{ this.props.status }</div>
      </div>
    }
    else {
      let state = this.state
      let canvasOffsetX = (state.className === 'right' ? this.CANVAS_WIDTH : 0)
      let barOffsetX = (state.isFallingFromRight ? 500 - this.BARCODE_WIDTH / 2 : -this.BARCODE_WIDTH)

      return <svg viewBox={ `${ canvasOffsetX } 0 ${this.CANVAS_WIDTH} ${this.CANVAS_HEIGHT}` } className='spinner'>
      {
        this.BARS.map((rect, i) =>
          <svg
            x={ rect.x * this.BARCODE_WIDTH + barOffsetX }
            key={ state.iteration + '' + i }
            >
            <rect
              x={ 500 + this.BARCODE_WIDTH / 2 }
              y='0'
              className={ i < state.currentlyFalling ? 'animate' : '' }
              width={ rect.w * this.BARCODE_WIDTH }
              height={ this.CANVAS_HEIGHT }
              fill='#000000' />
          </svg>
        )
      }
      </svg>
    }
  }
}