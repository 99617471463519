export const defaultReceiptData = {
  additional_information: {
    code: '578437597357',
    message: 'This is your tracking number, we guarantee 24h delivery',
    title: 'Your Tracking ID',
    type: 'CODE_128',
  },
  discounts: [
    {
      amount: {
        amount: 250,
        currency: 'EUR',
      },
      description: '25% healthy food discount',
    },
  ],
  display_configuration: {
    show_quantity: 'true',
    show_single_amount: 'true',
    show_tax_category: 'true',
  },
  invoice_number: '123',
  items: [
    {
      description: 'Fair Trade Bananas\n5kgx100',
      ean: '12345',
      product_category: '',
      quantity: 2,
      single_amount: {
        amount: 120,
        currency: 'EUR',
      },
      tax_category: 'A',
      total_amount: {
        amount: 240,
        currency: 'EUR',
      },
    },
    {
      description: 'Candy Sticks',
      ean: '12345',
      product_category: '',
      quantity: 5,
      single_amount: {
        amount: 100,
        currency: 'EUR',
      },
      sub_items: [
        {
          description: '50% off',
          quantity: 1,
          single_amount: {
            amount: -250,
            currency: 'EUR',
          },
          tax_category: 'B',
          total_amount: {
            amount: -250,
            currency: 'EUR',
          },
        },
      ],
      tax_category: 'B',
      total_amount: {
        amount: 500,
        currency: 'EUR',
      },
    },
  ],
  loyalty: [
    {
      closing_points_balance: 1180,
      loyalty_member_id: '987654321',
      opening_points_balance: 1200,
      points_gained: 30,
      points_spend: 50,
      title: 'Super Loyalty Card',
    },
  ],
  merchant: {
    branch: {
      bon: '1212',
      name: '125',
      cashier: 'Mary Middle',
      city: 'Innsbruck',
      phone: '+43512282828',
      pos: '2/12',
      register: 'RKID573688',
      area_id: '63a',
      table_id: '31',
      street: 'Andreas-Hofer-Strasse 13',
      website: 'www.Supermarked.at',
      zip: '6020',
    },
    name: 'Best Supermarked',
    uid: 'ATU12345123',
  },
  notes: 'Thank you for your purchase!',
  payments: [
    {
      acquirer_tx_id: '123456789',
      paid: {
        amount: 298,
        currency: 'EUR',
      },
      type: 'Bluecode',
    },
    {
      aid: '987',
      auth_code: '83hsku38',
      bid: '321',
      bin: '228392',
      last_four: '1234',
      paid: {
        amount: 500,
        currency: 'EUR',
      },
      tid: '654',
      type: 'DEBIT',
    },
    {
      gift_card_type: 'Friends and Family',
      paid: {
        amount: 298,
        currency: 'EUR',
      },
      type: 'Gift card',
    },
  ],
  pre_discount_amount: {
    amount: 120,
    currency: 'EUR',
  },
  signature: {
    qr_code_value: '12341234fjkasdfjksd',
    url: 'https://rksv.bluecode.com/12345',
    tse_timestamp_start: '2021-10-08T11:38:44.512835Z',
    tse_timestamp_end: '2021-10-08T11:38:45.632832Z',
    tse_transaction_number: '3789987',
    tse_signature_number: '978432',
    tse_serial_number: 'ABCD123876994234',
    tse_signature: 'sdjsdfesifuohjeruifa&dfwerfj*dsfjl+gefer==',
    tse_log_time_format: 'unix',
    tse_public_key:
      'YfrRlfgkljerRlkgjfp7wer78sdflKKitKLGWkwe66sdfcvKerkltePfghngoir89J=',
    tse_algorithm: 'ecdsa-plain-SHA256',
    tse_process_data: 'Data sklklerjewo2553',
    tse_process_type: 'Process 377',
    tse_first_order: 'order 101',
    tse_additional_legal_text:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
    tse_additional_data: [
      {
        key: 'info',
        display_name: 'Info',
        value:
          'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat',
      },
      {
        key: 'additional_data',
        display_name: 'Additional Data',
        value: 'At vero eos et accusam et justo duo dolores et ea rebum',
      },
    ],
  },
  taxes: [
    {
      description: 'A',
      percentage: '10',
      value_tax: {
        amount: 10,
        currency: 'EUR',
      },
      value_total: {
        amount: 100,
        currency: 'EUR',
      },
    },
    {
      description: 'B',
      percentage: '20',
      value_tax: {
        amount: 20,
        currency: 'EUR',
      },
      value_total: {
        amount: 100,
        currency: 'EUR',
      },
    },
  ],
  total_amount: {
    amount: 240,
    currency: 'EUR',
  },
  transaction_date_and_time: new Date(),
};
