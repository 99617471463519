import React, { Component } from 'react'
import './payment-components.css'
import { Card, Button, TextInput } from './util-components'
import { BarcodeScanner, ScanBarcodeButton, ALIPAY_REGEX, BLUE_CODE_REGEX } from './BarcodeScanner';

/**
 * These are the components involved in the payment workflow
 */

class MagicBarcode {
  /**
   * @param {string} barcode
   * @param {string} name
   */
  constructor(barcode, name) {
    this.barcode = barcode
    this.name = name
  }
}

const MAGIC_BARCODES = [
  new MagicBarcode('98802222100100123456', 'Immediate Success'),
  new MagicBarcode('98802222100100500001', 'Loyalty Reward'),
  new MagicBarcode('98800000000000000100', 'Instant Payment Success'),
  new MagicBarcode('98802222999900301000', 'Success after 5s'),
  new MagicBarcode('98800000000000000099', 'Refund OK'),
  new MagicBarcode('98802222999900315000', 'Timeout after 15s'),
  new MagicBarcode('98888888888888888888', 'Invalid barcode'),
  new MagicBarcode('98802222100100500503', 'Redeem timeout'),
  new MagicBarcode('98804444000000402006', 'INVALID_STATE'),
  new MagicBarcode('98804444000000402007', 'LIMIT_EXCEEDED'),
  new MagicBarcode('98802222999900500500', 'SYSTEM_FAILURE'),
]

/**
 * The list of magic barcodes
 * @param {Object} props
 * @param {(Barcode) => void} props.onSelect
 */
function MagicBarcodesList(props) {
  return <div className='magic-barcodes-list'>
    {
      MAGIC_BARCODES.map(magicBarcode =>
        <div
            className='barcode'
            key={ magicBarcode.barcode }
            onClick={() => props.onSelect(magicBarcode) }>
          <div className='logo'>
            <img src='/img/barcode.png' alt=''/>
          </div>
          <div className='name'>{
            magicBarcode.name
          }</div>
        </div>)
    }
  </div>
}

/**
 * Dialog for entering bar code and showing status.
 */
export class PaymentDialog extends Component {
  state = {
    barcode: '',
    isScannerOpen: false,
    isFuzzy: false
  }

  render() {
    let openScanner =
      () => this.setState({ isScannerOpen: true })

    let closeScanner =
      () => this.setState({ isScannerOpen: false })

    let onBarcodeDetected = (barcode) => {
      this.setState({
        barcode: barcode,
        isScannerOpen: false
      })

      this.props.onConfirm(barcode)
    }

    return <Card title='Bluecode Payment' className='payment-dialog'>
      {
        this.state.isScannerOpen ?
          <BarcodeScanner
            onBarcodeDetected={ onBarcodeDetected }
            onCancel={ closeScanner }
            matchRegexps={ [ ALIPAY_REGEX, BLUE_CODE_REGEX ] }
            barcodeTypeLabel='(Bluecode or Alipay) payment'
            />
          :
          []
      }
      <ScanBarcodeButton
          onClick={ openScanner }>
        <TextInput
          value={ this.state.barcode }
          onChange={ event =>
            this.setState({ barcode: event.target.value }) }
          placeholder='Enter barcode' />
      </ScanBarcodeButton>

      <div className='barcode-label'>
        Magic barcodes
      </div>

      <MagicBarcodesList onSelect={
        (barcode) => this.setState({ barcode: barcode.barcode })
      }/>

      <div className='button-bar'>
        <Button
            type='flat'
            onClick={ this.props.onCancel }>
          Cancel
        </Button>

        <Button
            type='flat'
            onClick={ () => this.props.onConfirm(this.state.barcode) }>
          Pay
        </Button>
      </div>
    </Card>
  }
}
