import React, { Component } from 'react'
import './loyalty-components.css'
import { TextInput, Card, Button } from './util-components';
import { BarcodeScanner, ScanBarcodeButton, BLUE_CODE_REGEX } from './BarcodeScanner';

export class LoyaltyDialog extends Component {
  constructor() {
    super()

    this.state = {
      barcode: '',
      kind: 'default',
      membershipNumber: '',
      isScannerOpen: false
    }
  }

  render() {
    let inputField = (property, placeholder, label) => 
      <div className='field'>
        <TextInput 
          value={ this.state[property] }
          onChange={ event => 
            this.setState({ [property]: event.target.value }) 
          }
          placeholder={ placeholder } 
          helper={ label } />
      </div>

    let update = () => {
      if (this.state.kind && this.state.barcode && this.state.membershipNumber) {
        this.props.onUpdate(this.state.membershipNumber, this.state.barcode, this.state.kind) 
      }
    }

    let openScanner = 
    () => this.setState({ isScannerOpen: true })
  
    let closeScanner = 
      () => this.setState({ isScannerOpen: false })

    let onBarcodeDetected = 
      (barcode) => {
        this.setState({ 
          barcode, 
          isScannerOpen: false 
        })
      }

    return <Card title='Loyalty Membership' className='loyalty-dialog'>
      {
        this.state.isScannerOpen ?
          <BarcodeScanner 
            onBarcodeDetected={ onBarcodeDetected } 
            onCancel={ closeScanner } 
            matchRegexps={ [ BLUE_CODE_REGEX ] }
            barcodeTypeLabel='Bluecode payment'
            errorThreshold={ 0.12 }
            />
          :
          []
      }

      { 
        inputField(
          'membershipNumber', 
          'Membership Number', 
          'Consumer identifier chosen by the merchant.')
      }

      { 
        inputField(
          'kind', 
          'Kind', 
          'Membership scheme identifier chosen by the merchant.')
      }

      <ScanBarcodeButton 
          onClick={ openScanner }>
        { 
          inputField(
            'barcode', 
            'Barcode', 
            'Payment barcode. The consumer will not be charged.') 
        }
      </ScanBarcodeButton>

      <div className='button-bar'>
        {
          this.props.canCancel ?
            <Button
              type='flat'
              onClick={ this.props.onCancel }>Cancel</Button>
            :
            []
        }

        <Button 
          type='flat'
          disabled={ !this.state.kind || !this.state.barcode || !this.state.membershipNumber }
          onClick={ update }>
          Update 
        </Button>
      </div>
    </Card>
  }
}
