import React, { Component } from 'react'
import './credentials-components.css'
import { TextInput, Checkbox, TextArea, Card, Button } from './util-components'
import { BlueCodeClient } from './client/BlueCodeClient'
import { getLocalStorage } from './util/local-storage'
import { defaultReceiptData } from './util/receipt-utils'
import { consoleProgress } from './client/console-progress';
import { getTerminalId } from './terminal-id';

const CREDENTIALS_KEY = 'credentials'
const CALLBACK_KEY = 'callbackUrl'
const CREATE_RECEIPT_KEY = 'createReceipt'
const RECEIPT_DATA_KEY = 'receiptData'
const DOMAIN_KEY = 'domain'

/**
 * @param {String} username
 * @param {String} password
 * @param {String} branch
 */
export function setCredentials(username, password, branch) {
  getLocalStorage().setItem(CREDENTIALS_KEY, JSON.stringify([username, password, branch]))
}

/**
 * @returns string[] An array of user name, password and branch. undefined if no credentials.
 */
export function getCredentials() {
  let json = getLocalStorage().getItem(CREDENTIALS_KEY)

  if (json) {
    let credentials = JSON.parse(json)

    let isIterable = (obj) => typeof credentials[Symbol.iterator] === 'function'

    if (isIterable(credentials)) {
      return credentials
    }
  }
}

export function getDomain() {
  return getLocalStorage().getItem(DOMAIN_KEY)
}

export function setDomain(domain) {
  getLocalStorage().setItem(DOMAIN_KEY, domain)
}

export function setCreateReceipt(createReceipt) {
  getLocalStorage().setItem(CREATE_RECEIPT_KEY, createReceipt)
}

export function getCreateReceipt() {
  let createReceipt = getLocalStorage().getItem(CREATE_RECEIPT_KEY) === 'true'
  return createReceipt
}
export function setReceiptData(data) {
  getLocalStorage().setItem(RECEIPT_DATA_KEY, data)
}

export function getReceiptData() {
  const json = getLocalStorage().getItem(RECEIPT_DATA_KEY)
  try {
    return JSON.parse(json)
  } catch (e) {
    return null
  }
}

export function setCallbackUrl(callbackUrl) {
  getLocalStorage().setItem(CALLBACK_KEY, callbackUrl)
}

export function getCallbackUrl() {
  return getLocalStorage().getItem(CALLBACK_KEY)
}

export class CredentialsDialog extends Component {
  constructor() {
    super()

    let [username, password, branch] = getCredentials() || ['', '', '']
    let callbackUrl = getCallbackUrl() || ''
    let domain = getDomain() || ''
    let createReceipt = getCreateReceipt() || false
    let receiptData = JSON.stringify((getReceiptData() || defaultReceiptData), null, 2)

    this.state = {
      username: username,
      password: password,
      branch: branch,
      callbackUrl: callbackUrl,
      domain: domain,
      createReceipt: createReceipt,
      receiptData: receiptData,
      operator: '',
      isValidating: false
    }
  }

  async validateCredentials() {
    if (!this.state.username
      || !this.state.password
      || !this.state.branch) {
      return false
    }

    let baseUrl = this.props.baseUrl || String.replace(document.location.host, "pos-example.", "")

    let client = new BlueCodeClient(this.state.username, this.state.password, baseUrl)

    this.setState({
      isValidating: true
    })

    try {
      await client.heartbeat(
        'ondemand',
        this.state.branch,
        getTerminalId(),
        consoleProgress)
    }
    catch (e) {
      let hostname = new URL(baseUrl).hostname

      if (e.code === 'BRANCH_NOT_FOUND') {
        this.setState({ error: 'Wrong branch. Check developer portal for right value.' })
        return false
      }

      if (e.code === 'UNAUTHORIZED') {
        this.setState({ error: 'Invalid credentials. ' + hostname + ' responds with UNAUTHORIZED.' })
        return false
      }

      if (e.code === 'TIMEOUT') {
        this.setState({
          error: 'Timeout calling ' + hostname +
            '. Does the server not allow connections from this domain (CORS)?'
        })

        return false
      }
    }
    finally {
      this.setState({
        isValidating: false
      })
    }

    return true
  }

  render() {
    let inputField = (property, placeholder, label, autoComplete) =>
      <div className='field'>
        <TextInput
          value={this.state[property]}
          autoComplete={autoComplete}
          onChange={event =>
            this.setState({ [property]: event.target.value })
          }
          placeholder={placeholder}
          helper={label} />
      </div>

      let checkbox = (property, placeholder, label) =>
      <div className='field'>
        <Checkbox
          value={this.state[property]}
          onChange={ event => {
              if (event.target.checked) {
                this.setState({ [property]: true })
              } else {
                this.setState({ [property]: false })
              }
            }
          }
          placeholder={placeholder}
          helper={label}
        />
      </div>

      let textArea = (property, placeholder, label, showTextArea) => {
      if (showTextArea) {
        return <div className='field'>
          <TextArea
            value={this.state[property]}
            onChange={event =>
              this.setState({ [property]: event.target.value })
            }
            placeholder={placeholder}
            helper={label} />
        </div>
        }
      }


    return <Card title='Credentials' className='credentials-dialog'>
      {
        this.state.error ?
          <div className='error'>
            {this.state.error}
          </div>
          :
          []
      }
      {
        inputField('username', 'Access ID', 'User name for API calls. See developer portal for credentials.', 'username')
      }
      {
        inputField('password', 'Access Secret Key (password)', 'Password for API calls.', 'current-password')
      }
      {
        inputField('branch', 'Branch', 'The branch identifier. See developer portal.', '')
      }
      {
        inputField('callbackUrl', 'Callback URL', <span>Optional. Webhook URL for QR Code transactions. Check out <a href="https://webhook.site">webhook.site</a></span>, '')
      }
      {
        checkbox('createReceipt', "Create Receipt", "Upon successful payment, send a create receipt.")
      }
      {
        textArea('receiptData', "Receipt Data", "Paste as object. Default data provided.", this.state.createReceipt)
      }
      {
        inputField('domain', 'Domain', 'Set domain used for API calls.', 'domain')
      }

      <div className='button-bar'>
        {
          this.props.canCancel ?
            <Button
              type='flat'
              onClick={this.props.onCancel}>Cancel</Button>
            :
            []
        }

        <Button
          type='flat'
          disabled={this.state.isValidating || !this.state.username || !this.state.password || !this.state.branch}
          onClick={async () => {
            let isValid = await this.validateCredentials()

            if (isValid) {
              setCredentials(this.state.username, this.state.password, this.state.branch)
              setCallbackUrl(this.state.callbackUrl)
              setDomain(this.state.domain)
              setCreateReceipt(this.state.createReceipt)
              setReceiptData(this.state.receiptData)
              this.props.onDone()
            }
          }}>Save</Button>
      </div>
    </Card>
  }
}
