import React, { Component } from 'react'
import './loyalty-components.css'
import { TextInput, Card, Button, Checkbox } from './util-components';


export class MerchantTokenDialog extends Component {
  constructor() {
    super()

    this.state = {
      exact_trx_amount: false
    }
  }

  render() {
    let inputField = (property, placeholder, label) => 
      <div className='field'>
        <TextInput 
          value={ this.state[property] }
          onChange={ event => 
            this.setState({ [property]: event.target.value }) 
          }
          placeholder={ placeholder } 
          helper={ label } />
      </div>

    let registerMerchantToken = () => {
      this.props.onRegister({
        trx_amount_limit: this.state.trx_amount_limit,
        currency: this.state.currency
      })
    }

    return <Card title='Merchant Token' className='payment-dialog'>
      { 
        inputField(
          'currency', 
          'Currency (default: none)', 
          'Currency in which must be used.')
      }

      {
        inputField(
          'trx_amount_limit', 
          'Transaction amount limit (default: none)', 
          'The max amount that can be charged using this merchant token.')
      }
 
      <div className='field'>
        <Checkbox
          onChange={ event => {
              if (event.target.checked) {
                this.setState({ exact_trx_amount: true })
              } else {
                this.setState({ exact_trx_amount: false })
              }
            }
          }
          placeholder={"Require exact amount (default: false)"}
          helper={"Require that the amount is the same as transaction amount limit."}
        />
      </div>

      {
        inputField(
          'ttl', 
          'TTL (default: 720000)', 
          'The amount of time in ms the token will be usable after creation.')
      }

      <div className='button-bar'>
        <Button
          type='flat'
          onClick={ this.props.onClose }>
            Close
        </Button>

        <Button 
          type='flat'
          onClick={registerMerchantToken}>
            Register
        </Button>
      </div>
    </Card>
  }
}