import React, { Component } from 'react'
import './App.css'
import { Order } from './model'
import { OrderCard, ProductSelectionCard } from './ordering-components'
import { PaymentDialog } from './payment-components'
import { MerchantTokenDialog } from './merchant-token-components'
import { CreditDialog } from './credit-components'
import { StatusDialog } from './status-log-components'
import { ModalOverlay, Button } from './util-components'
import { BlueCodeClient } from './client/BlueCodeClient'
import { CredentialsDialog, getCredentials, getCallbackUrl, getCreateReceipt, getReceiptData, getDomain } from './credentials-components';
import { RefundDialog } from './refund-components';
import { LoyaltyDialog } from './loyalty-components';
import { MESSAGES, ERROR_NON_CANCELED_TIMEOUTS, ERROR_SYSTEM_FAILURE, ERROR_CANCELED } from './util/error-messages'
import * as progress from './client/console-progress'  // eslint-disable-line no-unused-vars
import { Exception } from 'handlebars';
import { rewardedPayment } from './client/rewarded-payment';
import { ErrorResponse } from './client/ErrorResponse';
import { heartbeat } from './client/heartbeat';
import { getTerminalId } from './terminal-id';

const CENTS_PER_EURO = 100

/**
 * Converts from the amounts entered in the UI - which are in euros - to those
 * sent to the API - which are in cents (i.e. the minor currency unit).
 * @param {number} amountInMajorUnit
 */
function toMinorCurrencyUnit(amountInMajorUnit) {
  return Math.round(amountInMajorUnit * CENTS_PER_EURO)
}

/**
 @typedef { Object } paymentStatus
 @property { string[] } logEntries
 @property { string } status
 @property { () => Promise } cancel
 */

 /**
 @typedef { Object } merchantTokenStatus
 @property { string[] } logEntries
 @property { string } status
 @property { () => Promise } cancel
 */

class App extends Component {
  constructor() {
    super()

    /**
     * @property { string } paymentStatus.operationName
     * @property { string[] } paymentStatus.logEntries
     * @property { () => void } paymentStatus.cancel
     */
    this.state = {
      order: new Order(),
      isPaymentDialogOpen: false,
      isMerchantTokenDialogOpen: false,
      isCreditDialogOpen: false,
      isRefundDialogOpen: false,
      isLoyaltyDialogOpen: false,
      isCredentialsDialogOpen: !getCredentials(),
      // if present, a payment is in progress
      // and we show the payment status dialog
      paymentStatus: null,
      merchantTokenStatus: null,
      lastTransactionAcquirerTxId: null
    }
  }

  stopHeartbeat = () => { }

  /**
   * Starts the "heartbeat" process that informs BlueCode that the POS is online.
   * @see https://bluecodepayment.readme.io/v4/reference#heartbeat
   */
  restartHeartbeat() {
    this.stopHeartbeat()

    let [username, password, branchExtId] = getCredentials() // eslint-disable-line no-unused-vars

    // the heartbeat process returns a callback that should be
    // called when shutting down to send the shutdown event.
    this.stopHeartbeat = heartbeat(this.getClient(), branchExtId, getTerminalId())
  }

  componentDidMount() {
    if (getCredentials()) {
      this.getClient().nonCanceledTimeouts.retryPersisted()

      this.restartHeartbeat()
    }

    window.addEventListener('beforeunload', () => this.stopHeartbeat())
  }

  /**
   * @param {Product} product
   */
  addProductToOrder(product) {
    this.setState({
      order: this.state.order.add(product)
    })
  }

  renderPaymentDialog() {
    let close = () =>
      this.setState({ isPaymentDialogOpen: false })

    return <ModalOverlay
      onClose={close}>

      <PaymentDialog
        order={this.state.order}
        onCancel={close}
        onConfirm={
          (barcode) => {
            close()
            this.pay(barcode)
          }
        } />

    </ModalOverlay>
  }

  renderCreditDialog() {
    let close = () =>
      this.setState({ isCreditDialogOpen: false })

    return <ModalOverlay
      onClose={close}>

      <CreditDialog
      onCancel={close}
      onClose={close}
      onConfirm={
        (settings) => {
          close()
          this.creditRequest(settings)
        }
      } 
      />
      </ModalOverlay>
  }

  renderMerchantTokenDialog() {
    let close = () =>
      this.setState({ isMerchantTokenDialogOpen: false })

    return <ModalOverlay
      onClose={close}>

      <MerchantTokenDialog
      onCancel={close}
      onClose={close}
      onRegister={
        (settings) => {
          close()
          this.merchantTokenRegister(settings)
        }
      } 
      />
      </ModalOverlay>
  }

  renderMerchantTokenStatus() {
    let close = () =>
    this.setState({ merchantTokenStatus: null })

    let merchantTokenStatus = this.state.merchantTokenStatus

  return <StatusDialog
      title={merchantTokenStatus.operationName + ' Status'}
      logEntries={merchantTokenStatus.logEntries}
      result={merchantTokenStatus.result}
      status={merchantTokenStatus.status}
      onCancel={merchantTokenStatus.cancel}
      onClose={close} />
  }

  renderPaymentStatus() {
    let close = () =>
      this.setState({ paymentStatus: null })

    let paymentStatus = this.state.paymentStatus

    return <ModalOverlay
      onClose={close}>

      <StatusDialog
        title={paymentStatus.operationName + ' Status'}
        logEntries={paymentStatus.logEntries}
        status={paymentStatus.status}
        onCancel={paymentStatus.cancel}
        onClose={close} />

    </ModalOverlay>
  }

  renderCredentialsDialog() {
    let close = () => {
      getCredentials()

      this.restartHeartbeat()

      this.setState({
        isCredentialsDialogOpen: null
      })
    }

    return <ModalOverlay
      onClose={close}>

      <CredentialsDialog
        baseUrl={this.getBaseUrl()}
        onCancel={close}
        onDone={close}
        canCancel={!!getCredentials()}
      />

    </ModalOverlay>
  }

  renderRefundDialog() {
    let close = () =>
      this.setState({
        isRefundDialogOpen: null
      })

    return <ModalOverlay
      onClose={close}>

      <RefundDialog
        acquirerTxId={this.state.lastTransactionAcquirerTxId}
        onRefund={
          (acquirerTransactionId, amount, reason) => {
            this.setState({
              isRefundDialogOpen: false
            })

            this.refund(acquirerTransactionId, toMinorCurrencyUnit(amount), reason)
          }
        }
        onCancel={close}
        canCancel={true}
      />

    </ModalOverlay>
  }

  renderLoyaltyDialog() {
    let close = () =>
      this.setState({
        isLoyaltyDialogOpen: null
      })

    return <ModalOverlay
      onClose={close}>

      <LoyaltyDialog
        onUpdate={
          (membershipNumber, barcode, kind) => {
            this.setState({
              isLoyaltyDialogOpen: false
            })

            this.loyaltyUpdate(membershipNumber, barcode, kind)
          }
        }
        canCancel={true}
        onCancel={close}
      />

    </ModalOverlay>
  }

  render() {
    let openSettings = () =>
      this.setState({
        isCredentialsDialogOpen: true
      })

    let clear = () =>
      this.setState({
        order: new Order()
      })

    let pay = () => {
      if (this.state.order.getTotal() === 0) {
        return
      }

      // there are still transactions that timed out and where were
      // are unable to call cancel because that call also times out.
      // disallow transactions to prevent long queues of cancelations
      // from building up.
      if (this.getClient().nonCanceledTimeouts.isStillCanceling()) {
        alert(MESSAGES.en[ERROR_NON_CANCELED_TIMEOUTS])
      }
      else {
        this.setState({
          isPaymentDialogOpen: true
        })
      }
    }

    let merchantToken = () => {
      this.setState({
        isMerchantTokenDialogOpen: true
      })
    }

    let refund = () => {
      this.setState({
        isRefundDialogOpen: true
      })
    }

    let openCreditDialog = () => {
      this.setState({
        isCreditDialogOpen: true
      })
    }


    let loyaltyUpdate = () => {
      this.setState({
        isLoyaltyDialogOpen: true
      })
    }

    let selectProduct = (product) =>
      this.addProductToOrder(product)

    return (
      <div className='App'>
        <div className='cards'>
          <ProductSelectionCard
            onOpenSettings={openSettings}
            onProductSelect={selectProduct} />

          <OrderCard
            order={this.state.order}
            isPayEnabled={!this.state.order.isEmpty()}
            onClear={clear}
            onPayment={pay}
            onRegister={() => this.register()}
            />

          {
            (this.state.isPaymentDialogOpen ?
              this.renderPaymentDialog()
              :
              [])
          }

          {
            (this.state.paymentStatus ?
              this.renderPaymentStatus()
              :
              [])
          }

          {
            (this.state.merchantTokenStatus ?
              this.renderMerchantTokenStatus()
              :
              [])
          }

          {
            (this.state.isCredentialsDialogOpen ?
              this.renderCredentialsDialog()
              :
              [])
          }

          {
            (this.state.isMerchantTokenDialogOpen ?
              this.renderMerchantTokenDialog()
              :
              [])
          }

{
            (this.state.isCreditDialogOpen ?
              this.renderCreditDialog()
              :
              [])
          }


          {
            (this.state.isRefundDialogOpen ?
              this.renderRefundDialog()
              :
              [])
          }

          {
            (this.state.isLoyaltyDialogOpen ?
              this.renderLoyaltyDialog()
              :
              [])
          }
        </div>

        <div className='button-bar'>
          <Button
            type='inverse'
            onClick={refund}>Refund</Button>

          <Button
            type='inverse'
            onClick={openCreditDialog}>Credit</Button>

          <Button
            type='inverse'
            onClick={loyaltyUpdate}>Loyalty Membership</Button>

          <Button
            type='inverse'
            onClick={merchantToken}>Merchant Token</Button>
        </div>
      </div>
    )
  }

  /** @returns {progress.Progress} */
  getProgress(operationName) {
    let id = new Date().getTime()

    let paymentStatus = {
      logEntries: [],
      id,
      operationName
    }

    this.setState({ paymentStatus })

    let updatePaymentStatus = (callback) => {
      // the progress window has already been closed (paymentStatus is null)
      // or a new, different progress window has been opened (paymentStatus.id is a different id)
      if (!this.state.paymentStatus || this.state.paymentStatus.id !== id) {
        return
      }

      setTimeout(() => {
        this.setState({
          paymentStatus: callback(this.state.paymentStatus)
        })
      })
    }

    return {
      /**
       * @param status One of the STATUS_ or ERROR_ constants defined in error-messages.js.
       */
      onProgress: (message, status) =>
        updatePaymentStatus(paymentStatus => ({
          ...paymentStatus,
          logEntries: this.appendLogMessage(paymentStatus.logEntries, message),
          status: status || paymentStatus.status
        })),
      onCancellable: (cancel) =>
        updatePaymentStatus(paymentStatus => ({
          ...paymentStatus,
          cancel
        }))
    }
  }

  appendLogMessage(messages, message) {
    messages = messages || []

    if (message) {
      const lastMessage = messages[messages.length - 1]
      const multipleMessage = message + ' (multiple times)'

      if (lastMessage === message
        || lastMessage === multipleMessage) {
        return messages.slice(0, messages.length - 1).concat(multipleMessage)
      }
      else {
        return messages.concat(message)
      }
    }
    else {
      return messages
    }
  }

  getClient() {
    let credentials = getCredentials()

    if (!credentials) {
      throw new Exception('No credentials.')
    }

    let [username, password] = credentials

    return new BlueCodeClient(username, password, this.getBaseUrl())
  }

  getBaseUrl() {
    let domain = getDomain();

    if (domain) {
      console.log(`Using domain: ${domain}`)
    }
    else if (document.location.host.startsWith('pos-example.')) {
      domain = document.location.host.replace("pos-example.", "");
    }
    else {
      domain = 'bluecode.biz';
    }

    let url = "https://merchant-api." + domain + "/v4";

    return this.props.baseUrl || url;
  }

  async refund(acquirerTransactionId, amount, reason) {
    let client = this.getClient()

    try {
      await client.refund(
        acquirerTransactionId,
        amount,
        null,
        this.getProgress('Refund')
      )
    }
    catch (e) {
      console.error(e)
    }
  }

  async loyaltyUpdate(membershipNumber, barcode, kind) {
    let client = this.getClient()

    try {
      await client.loyaltyUpdate(
        membershipNumber,
        barcode,
        kind,
        this.getProgress('Loyalty')
      )
    }
    catch (e) {
      console.error(e)
    }
  }

  async creditRequest(settings) {
    let [username, password, branchExtId] = getCredentials() // eslint-disable-line no-unused-vars
    let client = this.getClient()
    let progress = this.getProgress('Credit')

    let creditOptions = {
      branchExtId,
      terminal: getTerminalId(),
      merchant_callback_url: getCallbackUrl() || "www.example.com",
      source: settings.source || "pos",
      amount: settings.amount || 100,
      currency: settings.currency || "EUR",
      reason: settings.reason || "deposit",
      purpose: settings.purpose || "Bluecode",
      entry_mode: settings.entry_mode || "scan",
      token: settings.token || ""
    }

    try {
      let response = await client.postCreditRequest(creditOptions, progress)
      if (response && response.state) {
        progress.onProgress(response.state)
      }

      let creditPayment = await client.waitForTerminalState(response.merchantTxId, progress)
      progress.onProgress(`Done. State: ${creditPayment.state}`, creditPayment.state)

    } catch (e) {
      if (e.wasCanceled) {
        progress.onProgress('Canceled.', ERROR_CANCELED)
      }
      else if (e instanceof ErrorResponse) {
        progress.onProgress('Client exception ' + e, ERROR_SYSTEM_FAILURE)
      }
      console.error(e)
    }
  }

  async merchantTokenRegister(settings) {
    let [username, password, branchExtId] = getCredentials() // eslint-disable-line no-unused-vars
    let client = this.getClient()
    let progress = this.getProgress('Merchant Token Register')

    let merchantTokenOptions = {
      branchExtId,
      terminal: getTerminalId(),
      merchant_callback_url: getCallbackUrl() || "www.example.com",
      source: settings.source || "pos",
      trx_amount_limit: settings.trx_amount_limit || null,
      exact_trx_amount: settings.exact_trx_amount || false,
      currency: settings.currency || null
    }

    try {
      let response = await client.registerMerchantToken(merchantTokenOptions, progress)
      if (response.checkinCode) {
        progress.onProgress(`![Check-in Code](${response.checkinCode})`)
      }

      let merchantToken = await client.waitForMerchantTokenState({registerId: response.registerId, branchExtId: merchantTokenOptions.branchExtId}, progress)

      progress.onProgress(`Done. State: ${merchantToken.state}`, merchantToken.state)

      if (merchantToken.merchantToken) {
        progress.onProgress(`Merchant Token: ${merchantToken.merchantToken}`)
        progress.onFinished(merchantToken.merchantToken)
      }
    } catch (e) {
      if (e.wasCanceled) {
        progress.onProgress('Canceled.', ERROR_CANCELED)
      }
      else if (e instanceof ErrorResponse) {
        progress.onProgress('Client exception ' + e, ERROR_SYSTEM_FAILURE)
      }
      console.error(e)
    }
  }

  async register() {
    if (this.state.order.getTotal() === 0) {
      return
    }

    let [username, password, branchExtId] = getCredentials() // eslint-disable-line no-unused-vars

    let client = this.getClient()

    let progress = this.getProgress('Payment')

    let totalAmount = toMinorCurrencyUnit(this.state.order.getTotal())

    let paymentOptions = {
      branchExtId,
      discountAmount: 0,
      paymentAmount: totalAmount,
      requestedAmount: totalAmount,
      terminal: getTerminalId(),
      merchant_callback_url: getCallbackUrl() || null,
      source: "ecommerce",
      entry_mode: "register"
    }

    try {
      let response = await client.register(paymentOptions, progress)

      if (response.checkinCode) {
        progress.onProgress(`![Check-in Code](${response.checkinCode})`)
      }
      else {
        progress.onProgress('Internal error: Got no check-in code.', ERROR_SYSTEM_FAILURE)
        return
      }

      let payment = await client.waitForTerminalState(response.merchantTxId, progress)

      progress.onProgress(`Done. State: ${payment.state}`, payment.state)
    }
    catch (e) {
      if (e.wasCanceled) {
        progress.onProgress('Canceled.', ERROR_CANCELED)
      }
      else if (e instanceof ErrorResponse) {
        progress.onProgress('Client exception ' + e, ERROR_SYSTEM_FAILURE)
      }

      console.error(e)
    }
  }

  async pay(barcode) {
    let [username, password, branchExtId] = getCredentials() // eslint-disable-line no-unused-vars

    let client = this.getClient()

    let progress = this.getProgress('Payment')

    try {
      let isRewardApplicable = (reward) => true

      let getDiscountedAmount = (originalAmount, rewards) => {
        // just to show the principle of discounts we give 50% discount for one reward, 66% for two etc...
        let discountedAmount = Math.round(originalAmount / (rewards.length + 1))

        if (rewards.length) {
          progress.onProgress(`Awarding ${Math.round(100 * rewards.length / (rewards.length + 1))}% discount. New total ${discountedAmount}.`)
        }

        return discountedAmount
      }

      let getPaymentOptions =
        (rewards) => {
          let totalAmount = toMinorCurrencyUnit(this.state.order.getTotal())
          let requestedAmount = getDiscountedAmount(totalAmount, rewards)

          return {
            barcode,
            branchExtId,
            discountAmount: totalAmount - requestedAmount,
            paymentAmount: totalAmount,
            requestedAmount,
            terminal: getTerminalId()
          }
        }

      let receiptOptions = {
        createReceipt: getCreateReceipt(),
        receiptData: getReceiptData()
      }

      let response = await rewardedPayment(
        barcode,
        isRewardApplicable,
        getPaymentOptions,
        receiptOptions,
        client,
        progress
      )

      this.setState({
        lastTransactionAcquirerTxId: response.acquirerTxId
      })
    }
    catch (e) {
      if (e.wasCanceled) {
        progress.onProgress('Canceled.', ERROR_CANCELED)
      }
      else if (!(e instanceof ErrorResponse)) {
        progress.onProgress('Client exception ' + e, ERROR_SYSTEM_FAILURE)
      }

      console.error(e)
    }
  }
}

export default App;
