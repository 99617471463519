import React, { Component } from "react";
import "./loyalty-components.css";
import { TextInput, Card, Button } from "./util-components";
import {
  BarcodeScanner,
  ScanBarcodeButton,
  BLUE_CODE_REGEX,
} from "./BarcodeScanner";

export class CreditDialog extends Component {
  constructor() {
    super();

    this.state = {
      barcode: "",
      isScannerOpen: false,
    };
  }

  render() {
    let openScanner = () => this.setState({ isScannerOpen: true });

    let closeScanner = () => this.setState({ isScannerOpen: false });

    let onBarcodeDetected = (barcode) => {
      this.setState({
        barcode: barcode,
        isScannerOpen: false,
      });

      this.props.onConfirm(barcode);
    };

    let inputField = (property, placeholder, label) => (
      <div className="field">
        <TextInput
          value={this.state[property]}
          onChange={(event) =>
            this.setState({ [property]: event.target.value })
          }
          placeholder={placeholder}
          helper={label}
        />
      </div>
    );

    let doCreditRequest = () => {
      this.props.onConfirm({
        amount: this.state.amount,
        currency: this.state.currency,
        reason: this.state.reason,
        purpose: this.state.purpose,
        token: this.state.barcode,
      });
    };

    return (
      <Card title="Credit" className="payment-dialog">
        {this.state.isScannerOpen ? (
          <BarcodeScanner
            onBarcodeDetected={onBarcodeDetected}
            onCancel={closeScanner}
            matchRegexps={[BLUE_CODE_REGEX]}
            barcodeTypeLabel="(Bluecode or Alipay) payment"
          />
        ) : (
          []
        )}

        {inputField("amount", "Amount (default: 100)", "The credit amount.")}

        {inputField(
          "currency",
          "Currency (default: EUR)",
          "Currency to be used for the credit."
        )}

        {inputField(
          "reason",
          "Reason (default: Deposit)",
          "The credit reason."
        )}

        {inputField(
          "purpose",
          "Purpose (default: Bluecode)",
          "The credit purpose."
        )}

        <ScanBarcodeButton onClick={openScanner}>
          <TextInput
            value={this.state.barcode}
            onChange={(event) => this.setState({ barcode: event.target.value })}
            placeholder="Enter barcode"
          />
        </ScanBarcodeButton>

        <div className="button-bar">
          <Button type="flat" onClick={this.props.onClose}>
            Close
          </Button>

          <Button type="flat" onClick={doCreditRequest}>
            Confirm
          </Button>
        </div>
      </Card>
    );
  }
}
